<template>
  <div class="is-flex input-none bg-white is-relative input-parent-style" :class="[parentClassName, {'input-search-2': !hideBorder}]">
    <div v-if="!hideFormat" class="flex-center p-2 bg-grey-2">
      <p class="averta-black color-grey">{{format}}</p>
    </div>
    <input
      type="text"
      class="p-2 c-w-100 bg-white input-style"
      :class="[className, {'has-text-right': !className.includes('has-text')}]"
      v-model="input"
      @input="formatPrice"
      placeholder="0"
      :disabled="disabled"
    />
    <p v-if="percent" class="color-grey percent-class py-2 pr-2">%</p>
  </div>
</template>

<script>
import { formatCurrency } from '@/helpers'
export default {
  name: 'InputPrice',
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    format: {
      type: String,
      default: 'Rp'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    percent: {
      type: Boolean,
      default: false
    },
    parentClassName: {
      type: String
    },
    className: {
      type: String,
      default: ''
    },
    hideFormat: {
      type: Boolean,
      default: false
    },
    hideBorder: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: null
    },
    maxValue: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      input: ''
    }
  },
  methods: {
    formatPrice (e) {
      let value = e.target.value
      if (value !== null) {
        const temp = value.toString().replace(/\D/g, '')
        let numericValue = Number(temp)

        if (this.percent && numericValue > 100) {
          numericValue = 100
        }

        if (this.maxValue && numericValue > this.maxValue) {
          numericValue = this.maxValue
        }

        this.input = numericValue.toLocaleString('id-ID')
        value = numericValue
      } else {
        this.input = ''
      }

      let res = JSON.parse(JSON.stringify(value))
      if (value < 0) {
        res = 0
      } else if (this.limit && value > this.limit) {
        res = this.limit
      } else {
        res = value
      }

      if (!this.disabled) {
        this.$emit('change', res)
      }
    },
    formatThousand (value) {
      return formatCurrency(value, true) === '-' ? 0 : formatCurrency(value, true)
    }
  },
  mounted () {
    if (this.value) {
      if (typeof this.value === 'string') {
        this.input = this.value
      } else {
        this.input = this.formatThousand(this.value)
      }
    }
  },
  watch: {
    value (val) {
      if (typeof val === 'string') {
        this.input = val
      } else {
        this.input = this.formatThousand(val)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.percent-class {
  margin-left: -7px;
}

.input-parent-style, .input-parent {
  transition: all 0.3s ease-in-out;
}

</style>
